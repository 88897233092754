import { CMSConfig } from './interfaces/cms-configuration-response.enum';

/**
 * @file app.constants.ts
 * @description Constants for app module
 */
export class AppConstants {
  public static readonly ERROR = {
    MESSAGES: {
      GENERIC: 'We ran into an unexpected error. Please try again.',
      FATAL: 'We ran into an unexpected error. Please refresh the application.',
    },
  };

  public static readonly LANGUAGE = {
    DEFAULT: 'en',
    ENGLISH: 'en',
    SPANISH: 'es',
  };

  public static readonly TEXT_SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
  };

  public static readonly LOADER = {
    DEFAULT_MESSAGE: 'Loading',
  };

  public static CMS_CONFIG_FALLBACK: CMSConfig = {
    clientSpecificConfig: {
      hideOutOfNetwork: false,
      disableDigitalIDCards: false,
      enableAutoChargeShip: false,
      suppressMailOrderFeatures: false,
      benefitBannerMessage:false,
      bannerMessage: '',
      enablePMartForm: false
    },
    links: [],
    pharmacySearchConfig: null,
    commonConfig: {}
  };

  public static GUEST_PROVIDER_TYPE = 'GUEST';
  public static PROVIDER_TYPE_ORION = 'ORION';
  public static PROVIDER_TYPE_BENECARD = 'BENECARD';
  public static PASSWORD_RESET_USER_FLOW_NAME = 'B2C_1_PasswordReset';

  public static MAIL_ORDER_NPIS = ['1003053174', '1114477122', '1821120981'];
  public static CLIENT_NAME = 'CLIENT_NAME';

  public static EXTERNAL_CLIENT_IDS_MAPPING = {
    CLIENT_ID: '28002',
    EXTERNAL_CLIENT_ID: 'WPEE001',
  };
}

export enum USER_ELIGIBILITY_STATUS {
  FUTURE_EFFECTIVE = 'FUTURE_EFFECTIVE',
  ELIGIBLE = 'ELIGIBLE',
  TERMED = 'TERMED',
}

export enum RELATIONSHIP_CODE {
  CARDHOLDER = '1',
  SPOUSE = '2',
  CHILD = '3',
  OTHER = '4',
  STUDENT = '5',
  DISABLED = '6',
  ADULT_DEPENDENT = '7',
  SIG_OTHER = '8',
}

export enum MEMBER_PORTAL_USER_TYPES {
  NORMAl = 0,
  SSO,
  GUEST,
}
