/**
 * @file cms.service.ts
 */
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Link } from 'src/app/interfaces/link.interface';
import { environment } from 'src/environments/environment';
import { AnswerSet } from '../../interfaces/answer-set.interface';
import { MultilingualService } from '../../core/services/multilingual/multilingual.service';
import { Term } from '../../interfaces/term.interface';
import { Documents } from 'src/app/interfaces/get-documents.interface';
import { CustomerSupport } from 'src/app/interfaces/customer-support-response.interface';
import { UserService } from '../user/user.service';
import { MaintenanceMode } from 'src/app/interfaces/maintenance-mode.interface';
import { TokenService } from 'src/app/core/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  answerSets: AnswerSet[] = [];

  constructor(
    private readonly http: HttpClient,
    private readonly multilingualService: MultilingualService,
    private readonly userService: UserService,
    private readonly tokenService: TokenService
  ) {
  }

  getLinks(): Observable<Link[]> {
    return this.http.get<Link[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/empirix-links`);
  }

  getEmpiRxFAQ(): Observable<AnswerSet[]> {
    return this.http.get<AnswerSet[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/faqs/${this.multilingualService.selectedLang}`);
  }

  getBenefitsFAQ(): Observable<AnswerSet[]> {
    return this.http.get<AnswerSet[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/benefits-page-faqs/en`);
  }

  geClinicalReviewFAQ(): Observable<AnswerSet[]> {
    return this.http.get<AnswerSet[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/clinical-review-faqs/en`);
  }

  getMailOrderFAQ(): Observable<AnswerSet[]> {
    return this.http.get<AnswerSet[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/mail-order-faqs/en`);
  }

  getPrivacyPolicy(): Observable<Term[]> {
    return this.http.get<Term[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/empirx-health-privacy-statement/${this.multilingualService.selectedLang}`);
  }

  getTermsOfUse(): Observable<Term[]> {
    return this.http.get<Term[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/terms-of-use/${this.multilingualService.selectedLang}`);
  }

  getDocuments(): Observable<Documents[]> {
    const providerType = this.tokenService.getProviderType();
    return this.http.get<Documents[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/pdf-download/?Providertype=${providerType}`);
  }

  getCustomerSupport(): Observable<CustomerSupport[]> {
    return this.http.get<CustomerSupport[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/customer-support/${this.multilingualService.selectedLang}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}`);
  }

  getConfigurationSettings(): Observable<any> {
    return this.http.get<any>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/configurationapi/${this.multilingualService.selectedLang}`);
  }

  getMaintenanceMode(): Observable<MaintenanceMode[]> {
    return this.http.get<MaintenanceMode[]>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/maintenance-mode`);
  }

  getAutoRefillAttestation(): Observable<{ title: string, attestationHtmlText: string }> {
    return this.http.get<{ title: string, attestationHtmlText: string }>(`${environment.hostURL}/CMSDocker/index.php/wp-json/wp/v2/pages/auto-refill-attestation/${this.multilingualService.selectedLang}`);
  }
}
