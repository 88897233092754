/**
 * @file claim.service.ts
 */
import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/http/http.service';
import { ClaimSearchDetailsResponse } from '../../interfaces/claim-search-detail-response.interface';
import { Observable } from 'rxjs';
import { ClaimSearchListResponse } from '../../interfaces/claim-search-list-response.interface';
import { UserService } from '../user/user.service';
import { APIConstants } from '../services.constants';
import { ClinicalReviewStatusResponse } from 'src/app/interfaces/clinical-review-status-response.interface';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  constructor(
    private readonly httpService: HttpService,
    private readonly userService: UserService,
  ) { }

  public getClaimDetailsByClaimId(claimId: string, personCode: string): Observable<ClaimSearchDetailsResponse> {
    return this.httpService.get<ClaimSearchDetailsResponse>(`${APIConstants.CLAIM_SERVICE.GET_CLAIM_ID}/${claimId}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}&cardId=${this.userService.eligibilityVerificationResponse?.cardId}&personCode=${personCode}`);
  }

  public getClaims(startDate?: string): Observable<ClaimSearchListResponse> {
    const member = this.userService.familyCoverageResponse?.members?.find(x => x.personCode == this.userService.eligibilityVerificationResponse?.personCode);

    if (member && member.allowedPhiPersonCodes.length) {
      return this.httpService.get<ClaimSearchListResponse>(`${APIConstants.CLAIM_SERVICE.GET_CLAIMS}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}&cardId=${this.userService.eligibilityVerificationResponse?.cardId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}&allowedPhiPersonCodes=${member.allowedPhiPersonCodes?.join(',')}&startDate=${startDate}&endDate=${this.formatDateRFC3339(new Date())}`);
    } else {
      return this.httpService.get<ClaimSearchListResponse>(`${APIConstants.CLAIM_SERVICE.GET_CLAIMS}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}&cardId=${this.userService.eligibilityVerificationResponse?.cardId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}&startDate=${startDate}&endDate=${this.formatDateRFC3339(new Date())}`);
    }
  }

  public getClinicalReview(): Observable<ClinicalReviewStatusResponse> {
    return this.httpService.get<ClinicalReviewStatusResponse>(`${APIConstants.CLAIM_SERVICE.GET_CLINICAL_REVIEW}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}&cardId=${this.userService.eligibilityVerificationResponse?.cardId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}`);
    //return this.httpService.get<ClinicalReviewStatusResponse>(`${APIConstants.CLAIM_SERVICE.GET_CLINICAL_REVIEW}?clientId=19001&cardId=TSTEPAPI6GH67&personCode=01`);
  }

  public formatDateRFC3339(date: Date): string {
    return `${date.getFullYear()}-${this.leftPadDateString(date.getMonth() + 1)}-${this.leftPadDateString(date.getDate())}`;
  }


  public getPreviousYear(date: Date, noOfYears: number): Date {
    return new Date(new Date(date).setFullYear(date.getFullYear() - noOfYears));
  }

  public get12hrTime(time24HrFormat: string): string {
    let time: string[] = time24HrFormat.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time24HrFormat];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = (+time[0] % 12 || 12).toString();
    }
    return time.join('');
  }

  public leftPadDateString(n: number): string {
    return `0000${n}`.slice(-2);
  }

}
